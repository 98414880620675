body {
    margin: 0;
    padding: 0;
}

.pulse-primary {
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse-primary 2s infinite;
}

@keyframes pulse-primary {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(144, 202, 249, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(144, 202, 249, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(144, 202, 249, 0);
    }
  }