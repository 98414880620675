.day {
    padding: 2px 2px;
    vertical-align: middle;
    text-align: center;
    border: 2px solid transparent;
    height: 23px;
    width: 29px;
    margin: auto;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
}

.month {
    padding: 8px 8px 8px 8px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.crossedOutLight:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 0%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transform: rotate(-30deg);
}

.crossedOutDark:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    color: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 0%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    transform: rotate(-30deg);
}

.hoverDayLight:hover {
    background-color: rgba(0, 0, 0, 0.26);
}

.hoverDayDark:hover {
    background-color: rgba(255, 255, 255, 0.3);
}