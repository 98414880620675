.langSwitchSelect {
    color: #ffffff !important;
    padding-top: 5px;
}

.langSwitchSelect::before,
.langSwitchSelect::after {
    border-bottom: 2px solid transparent !important;
}

.langSwitchSelect div:focus {
    background-color: inherit !important;
}

.langSwitchSelect svg {
    fill: #ffffff;
}
